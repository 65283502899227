import { ROUTES } from '~/constants/routes';
import type { AcceptanceStatus } from '~/layers/settlement/types/realty';
import { AcceptanceStatuses, AcceptanceUIStatuses  } from '~/layers/settlement/constants/realty';

export const useSettlementObjects = () => {
    const { t } = useI18n();

    // кнопка:
    // показываем ли кнопку в зависимости от статуса
    const isButtonShow = (status: AcceptanceStatus): boolean => {
        switch (status) {
        case AcceptanceStatuses.Participant:
        case AcceptanceStatuses.CheckPersonalData:
        case AcceptanceStatuses.ChooseDate:
        case AcceptanceStatuses.NeedPrepareDocuments:
        case AcceptanceStatuses.Meeting:
        case AcceptanceStatuses.ResultWithRemarks:
        case AcceptanceStatuses.FailResult:
        case AcceptanceStatuses.SuccessResult:
            return true;
        case AcceptanceStatuses.Undefined:
        case AcceptanceStatuses.Draft:
        default:
            return false; 
        }
    };

    // на основе статуса определяем текст кнопки
    const getButtonText = (status: AcceptanceStatus): string => {
        switch (status) {
        case AcceptanceStatuses.Participant:
        case AcceptanceStatuses.CheckPersonalData:
        case AcceptanceStatuses.ChooseDate:
            return t(`${'settlement.acceptance_status.get_keys'}`);
        case AcceptanceStatuses.NeedPrepareDocuments:
        case AcceptanceStatuses.Meeting:
        case AcceptanceStatuses.ResultWithRemarks:
        case AcceptanceStatuses.FailResult:
        case AcceptanceStatuses.SuccessResult:
            return t(`${'settlement.acceptance_status.information'}`);
        case AcceptanceStatuses.Undefined:
        case AcceptanceStatuses.Draft:
        default:
            return ""; 
        }
    };
    
    // на основе статуса определяем куда ведет кнопка (будет доработка)
    const getButtonLink = (status: AcceptanceStatus, acceptanceId: number | undefined): string => {
        if (!acceptanceId) {
            return `${ROUTES.acceptance}/error-link`;
        }

        switch (status) {
        case AcceptanceStatuses.Participant:
            return `${ROUTES.acceptance_id(acceptanceId)}?step=1`;  
        case AcceptanceStatuses.CheckPersonalData: 
            return `${ROUTES.acceptance_id(acceptanceId)}?step=2`; 
        case AcceptanceStatuses.ChooseDate: 
            return `${ROUTES.acceptance_id(acceptanceId)}?step=3`;
        case AcceptanceStatuses.NeedPrepareDocuments:
        case AcceptanceStatuses.Meeting: 
            return `${ROUTES.acceptance_id(acceptanceId)}?step=4`; 
        case AcceptanceStatuses.SuccessResult:
        case AcceptanceStatuses.ResultWithRemarks: 
        case AcceptanceStatuses.FailResult: 
        case AcceptanceStatuses.Cancelled: // вызвать модалку
            return `${ROUTES.acceptance_id(acceptanceId)}?step=5`; 
        case AcceptanceStatuses.Undefined:
        case AcceptanceStatuses.Draft:
        default:
            return `${ROUTES.acceptance}/error-link`;
        }
    };

    // редирект по клику на адрес из getButtonLink
    const goToPage = (status: AcceptanceStatus, acceptanceId: number | undefined) => {
        const link = getButtonLink(status, acceptanceId);
        navigateTo(link);
    };

    // на основе статуса определяем внешний вид кнопки
    function getButtonVariant(status: AcceptanceStatus): "flat" | "elevated" | "text" | "tonal" | "outlined" | "plain" {
        switch (status) {
        case AcceptanceStatuses.NeedPrepareDocuments:
        case AcceptanceStatuses.Meeting:
        case AcceptanceStatuses.ResultWithRemarks:
        case AcceptanceStatuses.FailResult:
        case AcceptanceStatuses.SuccessResult:
            return "outlined";
        case AcceptanceStatuses.Participant:
        case AcceptanceStatuses.CheckPersonalData:
        case AcceptanceStatuses.ChooseDate:
        case AcceptanceStatuses.Cancelled:
        case AcceptanceStatuses.Undefined:
        case AcceptanceStatuses.Draft:
        default:
            return "elevated";
        }
    }
    
    // на основе статуса определяем цвет chip (текст приходит в ответе на запрос)
    // если статус не пришел то чип не рисуем
    function getAcceptanceStatusColor(status: string): string {
        switch (status) {
        case AcceptanceStatuses.Draft:
        case AcceptanceStatuses.Participant:
        case AcceptanceStatuses.CheckPersonalData:
        case AcceptanceStatuses.ChooseDate:
            return "blue";
        case AcceptanceStatuses.Meeting:
        case AcceptanceStatuses.NeedPrepareDocuments:
            return "yellow";
        case AcceptanceStatuses.SuccessResult:
            return "green";
        case AcceptanceStatuses.ResultWithRemarks:
        case AcceptanceStatuses.FailResult:
            return "orange";
        default:
            return "";
        }
    }

    /** 
    * Определяет цвет селекта по UI статусам
    * @param status статус приемки
    */
    function getAcceptanceUIStatusColor(status: string): string {
        switch (status) {
        case AcceptanceUIStatuses.New:
        case AcceptanceUIStatuses.Invited:
            return "blue";
        case AcceptanceUIStatuses.Appointmented:
        case AcceptanceUIStatuses.Prepared:
            return "yellow";
        case AcceptanceUIStatuses.SuccessResult:
            return "green";
        case AcceptanceUIStatuses.ResultWithRemarks:
        case AcceptanceUIStatuses.FailResult:
            return "orange";
        case AcceptanceUIStatuses.Cancelled:
        default:
            return "";
        }
    }
    

    return {
        isButtonShow,
        getButtonText,
        getAcceptanceStatusColor,
        getAcceptanceUIStatusColor,
        getButtonVariant,
        goToPage,
    };
};